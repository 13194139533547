import { ref } from 'vue';

export const projects = ref([
  {
    id: 1,
    title: 'November 2023',
    subtitle: 'Enspirit Website',
    image: require('@/assets/enspirit.png'),
    stack: ['Astro', 'Tailwind CSS', 'JavaScript', 'Vue.js'],
    images: [
    require('@/assets/enspirit.png'),
    require('@/assets/enspirit.png'),

    ],
    url:'https://www.enspirit.dev/en/',
    type: 'Website, Astro',
    description: `
      This was my first project at Enspirit, where I contributed to building the website for Enspirit.be.
      The project introduced me to Astro, a modern framework, and required me to quickly
      adapt and learn it. I worked on several frontend tasks, including implementing
      design features, enhancing website functionality, and managing translations to ensure a smooth
      multilingual experience. We used Tailwind CSS for styling, which allowed us to create responsive
      and visually consistent components efficiently.
    `,
    subdescription: `
      This project provided me with a smooth introduction to working in a real-world development environment.
      Since it was an internal project rather than for an external client, it allowed me to gradually adapt
      to collaboration within a team, focus on refining my frontend skills, and explore new technologies like
      Astro in a supportive atmosphere.`
  },
  {
    id: 2,
    title: 'June 2024',
    subtitle: 'Coverseal Mobile application',
    image: require('@/assets/coverseal-dashboard-owner.png'),
    stack: ['Vue 3', 'Ionic'],
    images: [
    // require('@/assets/coverseal-pool.png'),
    require('@/assets/coverseal-1.png'),
    require('@/assets/coverseal-2.png'),
    require('@/assets/coverseal-3.png'),
    require('@/assets/coverseal-4.png'),
    require('@/assets/coverseal-5.png'),
    require('@/assets/coverseal-7.png'),
    require('@/assets/coverseal-8.png'),
    ],
    type: 'Mobile app, Vue 3',
    description: `
      I contributed to the development of the Coverseal app, focusing on
      frontend development using Vue 3 and Ionic frameworks.
      This project allowed me to delve into the Ionic framework and learn how
      to write tests with Cypress, which I thoroughly enjoyed. My work involved
      implementing design requirements and enhancing functionality throughout
      the application to ensure a user-friendly experience.
    `,
    subdescription: `
      Coverseal is a company specializing in advanced swimming pool covers. Founded in 2007 by Benoit Coenraets,
      who drew on many years of industrial experience—including his 1987 startup Dynaco,
      known for developing and globally marketing fast-opening automatic PVC doors across Europe,
      the USA, Japan, and Russia.After three years of dedicated research and continuous optimization,
      Coverseal introduced a swimming pool cover that remains unparalleled in the industry. Its numerous
      benefits and minimalist aesthetic blend seamlessly with any pool environment.`
  },
  {
    id: 3,
    title: 'January 2023',
    subtitle: 'Recipe app',
    image: require('@/assets/recipe.png'),
    stack: ['Astro', 'Tailwind CSS', 'JavaScript', 'Vue.js'],
    url: 'https://nimble-marshmallow-3c8cb3.netlify.app/',
    type: 'School project, Angular',
    description: 'A recipe app built during a school project using Angular framework to explore food-based APIs.',
  },
  {
    id: 4,
    title: 'December 2023',
    subtitle: 'Weather app',
    image: require('@/assets/weatherapp-one.png'),
    url: 'https://relaxed-toffee-bd9b76.netlify.app/',
    type: 'School project, React',
    description: 'A recipe app built during a school project using Angular framework to explore food-based APIs.',
  },
  {
    id: 5,
    title: 'November 2024',
    subtitle: 'QR Code Generator',
    image: require('@/assets/qr-code.png'),
    stack: ['Astro', 'Tailwind CSS', 'JavaScript', 'Vue.js'],
    url: 'https://main--comfy-cendol-5216ff.netlify.app/',
    type: 'Side project, React',
    description: 'A recipe app built during a school project using Angular framework to explore food-based APIs.',
  },

]);
