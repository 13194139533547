// main.js
import { createApp } from 'vue';
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import router from './router/index';

library.add(faReact);

const app = createApp(App);
app.use(router);
console.log('Router instance:', router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');

