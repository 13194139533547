<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const headers = [
  'Full-Stack Developer',
  'Problem Solver',
  'Photographer',
  'Team Player',
  'Continuous Learner',
];

const currentHeader = ref(headers[0]);
let headerIndex = 0;
let intervalId = null;

const changeHeader = () => {
  headerIndex = (headerIndex + 1) % headers.length;
  currentHeader.value = headers[headerIndex];
};

onMounted(() => {
  intervalId = setInterval(changeHeader, 2000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>


<template>
  <div class="about-section">
    <div class="about-image">
      <img
        src="@/assets/naelia.png"
        alt="naelia-image"
      >
    </div>

    <div class="about-content">
      <p class="about-title">
        About
      </p>
      <h2 class="animated-header">
        {{ currentHeader }}
      </h2>
      <div class="about-text">
        <p>
          I'm a 2-year full-stack developer student, excited to develop secure and good-looking solutions.
        </p>
        <p>
          <strong>My Approach:</strong> I enjoy solving problems and learning new technologies to enhance my
          development skills. I am highly motivated, diligent, and never give up when faced with challenges. I
          thrive in team environments but also work well independently.
        </p>
        <p>
          Here you can see my
          <a
            href="https://www.linkedin.com/in/naeliasalas/"
            target="_blank"
          >LinkedIn profile</a>, and here you can find my
          <a
            href="your-github-url"
            target="_blank"
          >GitHub</a>.
        </p>
        <p>
          I am proficient in communication, love spreading positive energy, and enjoy sharing laughter.
        </p>
      </div>
      <div class="about-btn">
      <a download="NaeliaSalas.pdf" href="/NaeliaSalas.pdf">
     <button class="cv-button"> Download full CV
    </button>
  </a>
</div>
    </div>
  </div>
</template>


<style scoped>
.about-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
  margin: 0;
}

/* Left Side Image */
.about-image {
  flex: 1;
  max-width: 50%;
  padding: 0;
  margin: 0;
}

.about-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

.about-content {
  flex: 1;
  max-width: 50%;
  padding: 50px;


  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-title {
  font-size: 24px;
  color: #00afea;
  margin-bottom: 10px;
}

.animated-header {
  color: #ffffff;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: white;
}

.about-text a {
  color: #00afea;
  text-decoration: none;
}

.about-text a:hover {
  text-decoration: underline;
}

.cv-button{
padding: 8px;
border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    height: auto; /* Adjust height for smaller screens */
  }

  .about-image,
  .about-content {
    max-width: 100%;
  }

  .about-image {
    margin-bottom: 20px;
    height: auto;
  }

  .about-image img {
    height: auto;
  }
}
</style>

