<script setup>
import { useRouter } from 'vue-router';
const emit = defineEmits(['close']);

const { project } = defineProps({
  project: Object,
});
const closeModal = () => {
  emit('close');
};
const router = useRouter();
console.log('Router instance:', router);

const goToProjectPage = (id) => {
  console.log(`Navigating to ProjectDetailView with id: ${id}`);
  closeModal();
  router.push({ name: 'ProjectDetailView', params: { id } }).catch(err => {
    console.error('Navigation error:', err);
  });
};
</script>

<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ project.title }}</h2>
        <button class="close-button" @click="closeModal">x</button>
      </div>
      <div class="modal-body">
        <div class="project-text">
          <p class="project-subtitle">{{ project.subtitle }}</p>
          <p class="project-type">{{ project.type }}</p>
          <p class="project-description">
            {{ project.description }}
          </p>
          <p class="project-description">
            {{ project.subdescription }}
          </p>
        </div>

        <div class="project-images">
          <div class="image-scroll">
            <img v-for="(image, index) in project.images" :key="index" :src="image" :alt="project.title" class="project-image" />
          </div>
        </div>
      </div>
      <div class="stack-section">
        <h2>Stack</h2>
        <ul class="stack-list">
          <li v-for="(stack, index) in project.stack" :key="index">{{ stack }}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button @click="goToProjectPage(project.id)" class="full-page-btn">
          See Full Page
        </button>
      </div>
    </div>
  </div>
</template>


<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  width: 80vw;
  max-width: 900px;
  height: 80vh;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.project-text {
  flex: 2;
  padding-right: 20px;
  max-width: 60%;
  line-height: 1.6;
}
.project-images {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  max-height: 100%;
  height: 80vh;
}

.project-image {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}

.image-scroll {
  max-height: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  padding-right: 10px;
}

.project-description {
  font-size: 16px;
  margin-top: 15px;
  color: #555;
  line-height: 1.8;
}

.stack-section {
  margin-top: -5px;
  margin-bottom: 15px;
  padding-left: 2px;
}

.stack-list {
  padding-left: 20px;
  margin-top: 8px;
  font-size: 16px;
  color: #333;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.full-page-btn {
  width: 20%;
  padding: 10px;
  background-color: #ffffff;
  color: white;
  font-size: 20px;
  cursor: pointer;
  color: #000000;
  border-radius: 6px;
  margin-top: 15px;
}

@media (max-width: 768px) {
  .project-images {
    display: none;
  }

  .modal-content {
    width: 90vw;
    height: auto;
    max-height: 90vh;
    padding: 20px;
    overflow-y: auto;
  }

  .project-text {
    max-width: 100%;
    padding-right: 0;
    font-size: 16px;
    line-height: 1.7;
  }

  .stack-section {
    margin-top: 20px;
  }

  .stack-list {
    margin-top: 10px;
    font-size: 16px;
  }
}

</style>
