<template>
  <div class="slider-container">
    <button class="arrow-btn prev" @click="prevSlide">
      <i class="fa fa-chevron-left"></i>
    </button>

    <div class="slider-wrapper" :style="sliderTransform">
      <div v-for="(project, index) in projects" :key="index" class="slider-item">
        <!-- Mobile: Route to Project Detail Page -->
        <router-link
          v-if="isMobile"
          :to="{ name: 'ProjectDetailView', params: { id: project.id } }"
        >
          <div class="project-content">
            <img :src="project.image" :alt="project.title" />
            <div class="project-overlay">
              <p class="project-date">{{ project.title }}</p>
              <h2 class="project-name">{{ project.subtitle }}</h2>
              <p class="project-type">{{ project.type }}</p>
            </div>
          </div>
        </router-link>

        <!-- Desktop: Open Modal -->
        <div v-else @click="openModal(project)">
          <div class="project-content">
            <img :src="project.image" :alt="project.title" />
            <div class="project-overlay">
              <p class="project-date">{{ project.title }}</p>
              <h2 class="project-name">{{ project.subtitle }}</h2>
              <p class="project-type">{{ project.type }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="arrow-btn next" @click="nextSlide">
      <i class="fa fa-chevron-right"></i>
    </button>

    <div class="progress-bar-container">
      <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
    </div>

    <!-- Modal for Desktop -->
    <ProjectModal
      v-if="showModal && !isMobile"
      :project="selectedProject"
      @close="closeModal"
    >
      <div class="modal-footer">
        <button @click="goToProjectPage(selectedProject.id)" class="full-page-btn">
          See Full Page
        </button>
      </div>
    </ProjectModal>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import ProjectModal from '../components/ProjectModal.vue';
import { projects } from '../utils/projectData';

const currentSlide = ref(0);
const itemsToShow = ref(2);
const totalSlides = computed(() => projects.length);
const showModal = ref(false);
const selectedProject = ref(null);

const isMobile = ref(window.innerWidth < 768);
const router = useRouter();

const openModal = (project) => {
  selectedProject.value = project;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const goToProjectPage = (id) => {
  router.push({ name: 'ProjectDetailView', params: { id } });
};

const progressWidth = computed(() => {
  const progress = (currentSlide.value / (totalSlides.value - itemsToShow.value)) * 100;
  return Math.min(progress, 100);
});

const updateItemsToShow = () => {
  const width = window.innerWidth;
  if (width < 768) {
    itemsToShow.value = 1;
  } else if (width < 1024) {
    itemsToShow.value = 2;
  } else {
    itemsToShow.value = 3;
  }
};

const nextSlide = () => {
  if (currentSlide.value < totalSlides.value - itemsToShow.value) {
    currentSlide.value++;
  }
};

const prevSlide = () => {
  if (currentSlide.value > 0) {
    currentSlide.value--;
  }
};

const sliderTransform = computed(() => {
  const translateX = currentSlide.value * (378 + 20);
  return {
    transform: `translateX(-${translateX}px)`,
  };
});

onMounted(() => {
  updateItemsToShow();
  window.addEventListener('resize', updateItemsToShow);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateItemsToShow);
});
</script>



<style scoped>
.slider-container {
  padding-top: 60px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;

}

.slider-item {
  flex: 0 0 auto;
  width: 600px;
  box-sizing: border-box;
  padding: 10px;
}

.project-content {
  position: relative;
  height: 900px;
  background-color: #161a2c;
  color: #ffffff;
  border: 2px solid #b9bbbe;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.project-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
  z-index: 1;
}

.project-content:hover::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.project-content:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

.project-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.project-content:hover img {
  transform: scale(1.1);
}

.project-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 2;
  color: white;
}

.project-content:hover .project-overlay {
  opacity: 1;
}

.project-content h2 {
  margin: 10px 0;
  font-size: 32px;
}

.project-content p {
  margin: 0;
  font-size: 18px;
  opacity: 0.8;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 10;
}

.slider-button:hover {
  opacity: 1;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.slider-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
}

.progress-bar {
  height: 100%;
  background-color: #ddd;

  transition: width 0.5s ease;
}
.full-page-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
}

.full-page-button:hover {
  background-color: #0056b3;
}
/* Responsive Adjustments */
@media (max-width: 1024px) {
  .project-content h2 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .project-content {
    height: 300px;
  }
}

.arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

</style>
