<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import './styles/global.css';

export default defineComponent({
  name: 'App',
  setup() {},
});
</script>

<style scoped>
.contact-page {
  padding-top: 100px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
