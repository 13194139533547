
<script setup>
</script>

<template>
  <section class="skills-section">
    <h2>Skills</h2>
    <div class="skills-container">
      <div class="skills-column">
        <h3>Frontend</h3>
        <ul>
  <li>
    <i class="fab fa-html5"></i> HTML & CSS
    <span class="sub-skills">+ Sass, Bootstrap, Tailwind</span>
  </li>
  <li>
    <i class="fab fa-js-square"></i> TypeScript
    <span class="sub-skills">+ plain JavaScript</span>
  </li>
  <li>
    <i class="fab fa-vuejs"></i> Vue
    <span class="sub-skills">+ Piña/Vuex</span>
  </li>
  <li>
    <i class="fab fa-angular"></i> Ionic
  </li>
  <li>
    <i class="fab fa-react"></i> React.js
  </li>
  <li>
    <i class="fab fa-angular"></i> Angular
  </li>
</ul>
      </div>
      <div class="skills-column">
        <h3>Backend</h3>
        <ul>
  <li>
    <i class="fab fa-node-js"></i> Node.js/Express
    <span class="sub-skills">+ with TypeScript</span>
  </li>
  <li>
    <i class="fab fa-docker"></i> Docker
  </li>
  <li>
    <i class="fas fa-database"></i> MySQL & MongoDB
    <span class="sub-skills">+ REST, mongoose</span>
  </li>
  <li>
    <i class="fab fa-php"></i> PHP
    <span class="sub-skills">+ Laravel</span>
  </li>
  <li>
    <i class="fas fa-project-diagram"></i> REST & GraphQL
    <span class="sub-skills">+ minimal API</span>
  </li>
  <h3>Testing</h3>
  <li>
    <i class="fas fa-project-diagram"></i> Cypress
  </li>

</ul>
      </div>
    </div>

    <!-- Contact button with envelope icon -->
    <a href="mailto:youremail@example.com">
      <button class="contact-button">Say hello <i class="fa fa-envelope"></i></button>
    </a>
  </section>
</template>



<style scoped>
.skills-section {
  padding: 30px;
  /* background-color: #4481e3; */
  background-color: #333;
  color: #333;
  border-radius: 25px;
  max-width: 1200px;
  margin: 50px auto;
}

.skills-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.skills-column {
  /* background-color: #4C56D9; */
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  width: 45%;
}

.sub-skills {
  font-size: 0.9rem;
  color: #c9d0f3;
  color: #333;

}

h2, h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  /* color: #f1f1f1; */
  color: #333;

}

.skills-column ul {
  list-style: none;
  padding-left: 0;
}

.skills-column ul li {
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.contact-button {
  background-color: #f1f1f1;
  /* color: #00afea; */
  color: #000;

  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-top: 15px;
}

.contact-button:hover {
  background-color: #d4d7ff;
}

.contact-button i {
  margin-left: 8px;
}
</style>
