<script setup>
import { onMounted, ref, watch } from 'vue';
import emailjs from 'emailjs-com';


const name = ref('');
const email = ref('');
const message = ref('');

// emailJS
const serviceID = process.env.VUE_APP_EMAILJS_SERVICE_ID;
const templateID = process.env.VUE_APP_EMAILJS_TEMPLATE_ID;
const publicKey = process.env.VUE_APP_EMAILJS_PUBLIC_KEY;
const successMessage = ref('');

// Validation
const nameError = ref('')
const emailError = ref('')
const messageError = ref('')


const validateEmail = (emailValue) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(emailValue);
};

onMounted(() => {
  emailjs.init(publicKey)
})

const handleSubmit = (e) => {
  e.preventDefault();

  nameError.value = '';
  emailError.value = '';
  messageError.value = '';

  if (!name.value) {
    nameError.value = 'Name is required.';
  }
  if (!email.value || !validateEmail(email.value)) {
    emailError.value = 'Please enter a valid email address.';
  }
  if (!message.value) {
    messageError.value = 'Message is required.';
  }
  if (!nameError.value && !emailError.value && !messageError.value) {
    const form = {
      user_name: name.value,
      user_email: email.value,
      message: message.value,
    };

    emailjs.send(serviceID, templateID, form)
      .then(() => {
        name.value = '';
        email.value = '';
        message.value = '';
        successMessage.value = 'Great, I have recived your email!';
      }, (error) => {
        console.log('FAILED...', error.text);
        alert('Failed to send your message. Please try again later.');
      });
  }
};

watch([name, email, message], ([newName, newEmail, newMessage]) => {
  if(newName) {
    nameError.value = '';
  }
  if(newEmail && validateEmail(newEmail)){
    emailError.value = ''
  }
  if(newMessage){
    messageError.value = ''
  }
});
</script>

<template>
  <div class="two-column-section">
    <div class="left-column">
      <div class="contact-form">
        <h2>Say Hello</h2>
        <form
          novalidate
          @submit="handleSubmit"
        >
          <div class="form-group">
            <label for="name">NAME</label>
            <input
              id="name"
              v-model="name"
              type="text"
              placeholder="Enter your name"
            >
            <span
              v-if="nameError"
              class="error-message"
            >{{ nameError }}</span>
          </div>

          <div class="form-group">
            <label for="email">EMAIL</label>
            <input
              id="email"
              v-model="email"
              type="email"
              placeholder="Enter your email"
            >
            <span
              v-if="emailError"
              class="error-message"
            >{{ emailError }}</span>
          </div>

          <div class="form-group">
            <label for="message">MESSAGE</label>
            <textarea
              id="message"
              v-model="message"
              placeholder="Enter your message"
            />
            <span
              v-if="messageError"
              class="error-message"
            >{{ messageError }}</span>
          </div>

          <div class="button-email-container">
            <button type="submit">
              Hit me up!
            </button>
            <p class="email-address">
              naeliasalas@gmail.com
            </p>
          </div>
          <div
            v-if="successMessage"
            class="success-message"
          >
            {{ successMessage }}
          </div>
        </form>
      </div>
    </div>
    <div class="right-column">
      <h1>Wanna talk and see what we can do?</h1>
    </div>
  </div>
</template>


<style scoped>
.two-column-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  margin-bottom: 50px;
}

.left-column {
  flex: 1;
  max-width: 50%;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.contact-form {
  width: 100%;
}

.contact-form h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 24px;
  text-align: left;
}

.form-group {
  margin-bottom: 24px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #333;
  font-size: 18px;
  color: #96A1C0;
}

input,
textarea {
  width: 100%;
  padding: 16px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: rgb(45, 54, 79);
  background-color: #161a2c;
  color: #ffffff;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #00afea;
}

textarea {
  min-height: 100px;
}

button {
  width: 30%;
  padding: 16px;
  background-color: #ffffff;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000000;
}

button:hover {
  background-color: #00afea;
}

button:active {
  background-color: #2a3b3b;
}

.button-email-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.email-address {
  color: #96A1C0;
  font-size: 22px;
}

.email-address:hover {
  color: white;
}

.right-column {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
}

.right-column h1 {
  font-size: 48px;
  color: #333;
  text-align: center;
  margin-bottom: 24px;
  color: #ffffff;

}

.right-column .subtitle {
  font-size: 24px;
  color: #555;
  text-align: center;
}

.right-column .highlight {
  color: #000;
  font-weight: bold;
}

.success-message {
  margin-top: 20px;
  color: #78d97b;
  font-size: 14px;
  /* border: 1px solid #4CAF50; */
  padding: 10px;
  /* border-radius: 5px; */
  /* background-color: #e6ffe6; */
  text-align: center;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
/* Responsive Design */
@media (max-width: 768px) {
  .two-column-section {
    flex-direction: column;
    height: auto;
  }

  .left-column,
  .right-column {
    max-width: 100%;
    height: auto;
  }

  .left-column {
    align-items: center;
  }

  .contact-form {
    width: 100%;
    max-width: 100%;
  }

  .right-column {
    padding: 20px;
  }
}
</style>



