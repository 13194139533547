<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "Navigation",
  setup() {
    const scrolledNav = ref(false);
    const mobile = ref(false);
    const mobileNav = ref(false);
    const navLoaded = ref(false);
    const router = useRouter();
    const route = useRoute();


    const toggleMobileNav = () => {
      mobileNav.value = !mobileNav.value;
    };

    const updateScroll = () => {
      scrolledNav.value = window.scrollY > 50;
    };

    const checkScreen = () => {
      mobile.value = window.innerWidth <= 750;
      if (!mobile.value) {
        mobileNav.value = false;
      }
    };

    const handleNavigation = (event, sectionId) => {
      event.preventDefault();
      if (mobileNav.value) {
        mobileNav.value = false;
      }
      if (route.path === '/') {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        router.push({ path: '/', hash: `#${sectionId}` });
      }
    };

    onMounted(() => {
      window.addEventListener('resize', checkScreen);
      window.addEventListener('scroll', updateScroll);
      checkScreen();
      setTimeout(() => {
        navLoaded.value = true;
      }, 600);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkScreen);
      window.removeEventListener('scroll', updateScroll);
    });

    return {
      scrolledNav,
      mobile,
      mobileNav,
      toggleMobileNav,
      navLoaded,
      handleNavigation,
    };
  },
};
</script>

<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="branding">
        <a
          class="logo"
          href="#home"
        >NSA</a>
      </div>
      <ul v-show="!mobile" :class="['navigation', { 'nav-loaded': navLoaded }]">
      <li>
        <a
          class="link"
          href="#about"
          @click="handleNavigation($event, 'about')"
        >About</a>
      </li>
      <li>
        <a
          class="link"
          href="#projects"
          @click="handleNavigation($event, 'projects')"
        >Projects</a>
      </li>
      <li>
        <a
          class="link"
          href="#contact"
          @click="handleNavigation($event, 'contact')"
        >Contact</a>
      </li>
    </ul>
      <div class="icon">
        <i
          v-show="mobile"
          class="fa fa-bars"
          :class="{'icon-active': mobileNav}"
          @click="toggleMobileNav"
        />
      </div>
      <transition name="mobile-nav">
  <ul v-show="mobileNav" class="dropdown-nav">
    <!-- <li>
      <a
        class="link"
        href="#home"
        @click="handleNavigation($event, 'home')"
      >Home</a>
    </li> -->
    <li>
      <a
        class="link"
        href="#about"
        @click="handleNavigation($event, 'about')"
      >About</a>
    </li>
    <li>
      <a
        class="link"
        href="#projects"
        @click="handleNavigation($event, 'projects')"
      >Projects</a>
    </li>
    <li>
      <a
        class="link"
        href="#contact"
        @click="handleNavigation($event, 'contact')"
      >Contact</a>
    </li>
  </ul>
</transition>
    </nav>
  </header>
</template>
<style scoped>
.navigation {
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.5s ease-out;
}

.nav-loaded {
  opacity: 1;
  transform: translateY(0);
}

.nav-loaded li {
  animation: dropIn 0.6s ease-out forwards;
}

@keyframes dropIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.link {
  text-decoration: none;
  color: white;
  padding: 10px;
}


@media (max-width: 750px) {
  .icon {
    display: block;
  }
  .navigation {
    display: none;
  }
}

.dropdown-nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;
   z-index: 9999;
   opacity: 1;
   transform: translateX(0);
}

.dropdown-nav li {
  margin-bottom: 20px;
}


.icon {
  margin-top: 20px;
}
@media (max-width: 750px) {
  .link {
    color: black;
    font-size: 24px;
    font-weight: bold;
  }
  .icon {
    display: block;
  }
  .navigation {
    display: none;
  }
}
</style>
