<script>
import AboutView from './AboutView.vue';
import ContactView from './ContactView.vue';
import ProjectsView from './ProjectsView.vue';
import Navigation from '../components/Navigation.vue';
import Skills from '../components/Skills.vue';
import ProjectDetailView from './ProjectDetailView.vue';

export default {
  name: 'Home',
  components: {
    AboutView,
    ContactView,
    ProjectsView,
    Navigation,
    Skills,
    ProjectDetailView,
  },
  data() {
    return {
      developerLetters: 'Developer'.split(''),
      selectedProject: null, // Selected project data
    };
  },
  methods: {
    showProjectDetails(project) {
      // Set selected project to show in the details section or modal
      this.selectedProject = project;
    },
    closeProjectDetails() {
      this.selectedProject = null;
    },
  },
};
</script>

<template>
  <Navigation />

  <div class="home">
    <section id="home" class="hero">
      <div class="hero-text container">
        <h4>Naelia Salas</h4>
        <div class="fullstack-wrapper">
          <span class="fullstack-left outlined-text">FULL</span>
          <span class="fullstack-right outlined-text">STACK</span>
        </div>
        <hr class="animated-line">
        <div class="developer-wrapper">
          <span v-for="(letter, index) in developerLetters" :key="index" class="developer-letter">{{ letter }}</span>
        </div>
      </div>
    </section>
    <section id="about">
      <h2>About Me</h2>
      <AboutView />
    </section>
    <section id="skills">
      <Skills />
    </section>
    <section id="projects">
      <ProjectsView @showProjectDetails="showProjectDetails" />
    </section>
    <section id="contact">
      <h2>Contact Me</h2>
      <ContactView />
    </section>
    <ProjectDetailView v-if="selectedProject" :project="selectedProject" @close="closeProjectDetails" />
  </div>
</template>


<style lang="scss" scoped>
.navbar {
  background-color: #333;
  padding: 20px;
  text-align: center;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar ul li {
  display: inline;
  margin: 0 15px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.navbar ul li a:hover {
  text-decoration: underline;
}

/* Styling for the sections */
.hero, .about-section, .projects-section, .contact-section {
  padding: 80px 0;
  text-align: center;
}
.hero {
  background-image: url("../assets/darkBlueGradient.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    width: 100%;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }
    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
  .fullstack-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 20px 0;
}

.fullstack-left, .fullstack-right {
  font-size: 58px;
  font-weight: bold;
  opacity: 0;
  animation: slide-in 1s ease forwards;
}

.fullstack-left {
  transform: translateX(-100%);
  animation-delay: 0.2s;
}

.fullstack-right {
  transform: translateX(100%);
  animation-delay: 0.4s;
}

@keyframes slide-in {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.developer-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.developer-letter {
  font-size: 48px;
  font-weight: bold;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInLetter 0.3s forwards ease-in;
}

.developer-letter:nth-child(1) { animation-delay: 0.1s; }
.developer-letter:nth-child(2) { animation-delay: 0.2s; }
.developer-letter:nth-child(3) { animation-delay: 0.3s; }
.developer-letter:nth-child(4) { animation-delay: 0.4s; }
.developer-letter:nth-child(5) { animation-delay: 0.5s; }
.developer-letter:nth-child(6) { animation-delay: 0.6s; }
.developer-letter:nth-child(7) { animation-delay: 0.7s; }
.developer-letter:nth-child(8) { animation-delay: 0.8s; }
.developer-letter:nth-child(9) { animation-delay: 0.9s; }

@keyframes fadeInLetter {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animated-line {
  width: 0;
  height: 2px;
  background-color: #fff;
  border: none;
  margin: 20px auto;
  opacity: 0;
  animation: slideInLine 1.2s forwards ease-out;
  animation-delay: 0.5s;
  max-width: 60%;

}

@keyframes slideInLine {
  to {
    width: 100%;
    opacity: 1;
  }
}
}


</style>
